<template>
  <div class="settings__pages">
    <h1>Settings</h1>
    <v-row>
      <template v-for="(page, index) in pages">
        <v-col
          v-if="page.router !== 'website.settings.payments' ? true : !enableRef"
          cols="12"
          md="4"
          sm="12"
          xs="12"
          xl="3"
          :key="index"
        >
          <v-card class="d-flex align-start pa-3" height="100%" style="gap: 16px" link @click="onSelect(page)">
            <div class="pa-4 border rounded">
              <div class="d-flex justify-center align-center primary rounded" style="width: 32px; height: 32px;">
                <i :class="page.icon" style="font-size: 20px; color: white;"></i>
              </div>
            </div>
            <div>
              <h3>{{ page.title }}</h3>
              <p>{{ page.des }}</p>
            </div>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import utilities from '@/helpers/utilities';
import { domainService } from '@/apis/domain.s';

export default {
  computed: {
    ...mapGetters(['user']),
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
  },

  data() {
    return {
      pages: [
        {
          icon: 'ri-account-circle-line',
          title: 'Account',
          des: 'Manage staff accounts and permissions',
          router: 'website.settings.account',
        },

        {
          icon: 'ri-megaphone-line',
          title: 'Sale Channels',
          des: 'Manage the channels you use to sell your products and services',
          router: 'website.settings.sale-channels',
        },
        {
          icon: 'ri-truck-line',
          title: 'Shipping',
          des: 'Manage how you ship orders to customers',
          router: 'website.settings.shipping',
        },
        {
          icon: 'fas fa-shopping-cart',
          title: 'Checkout',
          des: 'Customize your online checkout process',
          router: 'website.settings.checkout',
        },
        // {
        //   icon: 'ri-bank-card-line',
        //   title: 'Payout',
        //   des: 'Setup and Manage your payouts',
        //   router: 'website.settings.payout',
        // },
        // {
        //   icon: 'fas fa-user-circle',
        //   title: 'Domain',
        //   des: 'Manage your domain',
        //   router: 'website.domain',
        // },

        // {
        //   icon: 'fas fa-wrench',
        //   title: 'Preferences',
        //   des: 'Favicon, Title and meta description, Additional scripts',
        //   router: 'website.preferences',
        // },

        // {
        //   icon: 'fas fa-shopping-bag',
        //   title: 'Proxy',
        //   des: 'Manage proxy',
        //   router: 'website.settings.proxy',
        // },
        // {
        //   icon: 'far fa-credit-card',
        //   title: 'Payment providers',
        //   des: `Enable and manage your store's payment providers`,
        //   router: 'website.settings.payments',
        // },
      ],
      enableRef: false,
      paymentChoice: null,
    };
  },
  methods: {
    async checkEnableRef() {
      try {
        const { data } = await domainService.checkEnableRef();
        if (data) {
          this.enableRef = data[0].enableRef;
        }
      } catch (error) {
        console.log('🚀 ~ file: index.vue:137 ~ checkEnableRef ~ error:', error);
      }
    },
    onSelect(page) {
      this.$router.push({
        name:
          page.router !== 'website.settings.payments' || utilities.checkPermisson(`payment_providers`, this.user)
            ? page.router
            : 'no-access',
      });
    },
  },
  beforeMount() {
    let currentUser = this.$store.state.auth?.user || null;
    if (!currentUser) {
      currentUser = JSON.parse(localStorage.getItem('dcomcy-user'));
    }
    if (
      currentUser?.email?.indexOf('@lattehub.com') > -1 ||
      ['support@dcomcy.com', 'admin@dcomcy.com', 'nguyenduy@dcomcy.com', 'account@ak11.work'].indexOf(
        currentUser?.email,
      ) > -1
    ) {
      this.pages = this.pages.concat([
        {
          icon: 'fas fa-cog',
          title: 'General',
          des: 'View and update your store details',
          router: 'website.settings.general',
        },

        {
          icon: 'far fa-bell',
          title: 'Notification',
          des: 'Manage notifications sent to you and your customers',
          router: 'website.settings.notifications',
        },
        {
          icon: 'fas fa-balance-scale-right',
          title: 'Legal',
          des: `Manage your store's legal pages`,
          router: 'website.settings.legal',
        },
        {
          icon: 'fas fa-file-invoice-dollar',
          title: 'Billing',
          des: 'Manage your billing information and view your invoices',
          router: 'website.settings.billing',
        },
        {
          icon: 'fas fa-file-invoice-dollar',
          title: 'Taxes',
          des: 'Manage how your store charge taxes',
          router: 'website.settings.taxes',
        },
      ]);
    }
  },
  mounted() {
    this.checkEnableRef();
    this.paymentChoice = this.pagePreference?.paymentChoice?.type || 'seller';
    if (this.paymentChoice === 'seller') {
      this.pages.push({
        icon: 'far fa-credit-card',
        title: 'Payment providers',
        des: `Enable and manage your store's payment providers`,
        router: 'website.settings.payments',
      });
    }
  },
  watch: {
    // '$store.state.pagePreference.preference'() {
    //   this.paymentChoice = this.pagePreference?.paymentChoice?.type || 'seller';
    //   if (this.paymentChoice === 'seller') {
    //     this.pages.push({
    //       icon: 'far fa-credit-card',
    //       title: 'Payment providers',
    //       des: `Enable and manage your store's payment providers`,
    //       router: 'website.settings.payments',
    //     });
    //   }
    // },
  },
};
</script>
